import _arrayMap from "./_arrayMap";
import _baseIteratee from "./_baseIteratee";
import _basePickBy from "./_basePickBy";
import _getAllKeysIn from "./_getAllKeysIn";
var exports = {};
var arrayMap = _arrayMap,
  baseIteratee = _baseIteratee,
  basePickBy = _basePickBy,
  getAllKeysIn = _getAllKeysIn;

/**
 * Creates an object composed of the `object` properties `predicate` returns
 * truthy for. The predicate is invoked with two arguments: (value, key).
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Object
 * @param {Object} object The source object.
 * @param {Function} [predicate=_.identity] The function invoked per property.
 * @returns {Object} Returns the new object.
 * @example
 *
 * var object = { 'a': 1, 'b': '2', 'c': 3 };
 *
 * _.pickBy(object, _.isNumber);
 * // => { 'a': 1, 'c': 3 }
 */
function pickBy(object, predicate) {
  if (object == null) {
    return {};
  }
  var props = arrayMap(getAllKeysIn(object), function (prop) {
    return [prop];
  });
  predicate = baseIteratee(predicate);
  return basePickBy(object, props, function (value, path) {
    return predicate(value, path[0]);
  });
}
exports = pickBy;
export default exports;